@media (min-width:1200px) and (max-width:1450px) {
    nav ul li {
        padding: 15px 15px;
    }
    header {
        width: 230px;
    }
}
@media (max-width:1199px) {
    .main-content-bottom {
        grid-template-columns: 1fr;
    }
    header {
        width: 230px;
    }
}
@media (max-width:991px) {
    thead th {
        text-align: center;
    }
    tbody td {
        text-align: center;
    }
    .main-content-top ul.btn-list li a {
        padding: 10px 20px;
        font-size: 16px;
    }
    header {
        width: 200px;
    }
    nav ul li {
        padding: 20px 10px;
    }
    nav ul li a {
        font-size: 16px;
    }
}
@media (max-width:767px) {
    header {
        width: 200px;
        position: absolute;
        z-index: 10;
    }
    .main-wrapper {
        grid-template-areas: 
        "main-content  main-content"
        "footer footer";
    }
    .table-responsive {
        width: 100%;
        overflow-x: auto; 
    }
}